<template>
  <div>
    <div class="mobileNavigation">
      <div
        class="row"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <img :src="logoImgUrl" style="height: 2rem; margin: 7px" />
        </div>
        <div style="padding-right: 24px; font-size: 20px; margin-right: 10px;">
          <i @click="toggleMobileNavBar" class="fas fa-bars"></i>
        </div>
      </div>
    </div>

    <div v-if="firstValue" class="mobileBar">
      <div :class="mobileNavBarAnimation()">
        <div
          class="col-md-12"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 60px;
          "
        >
          <router-link to="/home-page">
            <div class="mobilNavbarItem" @click="mobilSelectItem('Home')">
              <i
                class="mobilNavbarItemIcon fas fa-home mr-1"
                style="color: #5593f7"
              ></i>
              <span class="mobilNavbarItemText">Home</span>
            </div>
          </router-link>

          <div class="mobilMenuHr"></div>

          <router-link to="/customer-secure-portal">
            <div class="mobilNavbarItem" @click="mobilSelectItem('Messages')">
              <i
                class="mobilNavbarItemIcon fas fa-comments mr-1"
                style="color: #5593f7"
              ></i>
              <span class="mobilNavbarItemText">Messages</span>
            </div>
          </router-link>

          <div class="mobilMenuHr"></div>

          <router-link to="/creditors-screen">
            <div class="mobilNavbarItem" @click="mobilSelectItem('Creditors')">
              <i
                class="mobilNavbarItemIcon fas fa-file-invoice-dollar mr-1"
                style="color: #5593f7"
              ></i>
              <span class="mobilNavbarItemText">Creditors</span>
            </div>
          </router-link>

          <div class="mobilMenuHr"></div>

          <router-link to="/documents">
            <div class="mobilNavbarItem" @click="mobilSelectItem('Documents')">
              <i
                class="mobilNavbarItemIcon fas fa-file-alt mr-1"
                style="color: #5593f7"
              ></i>
              <span class="mobilNavbarItemText">Documents</span>
            </div>
          </router-link>

          <div class="mobilMenuHr"></div>

          <router-link to="/financial-report">
            <div
              class="mobilNavbarItem"
              @click="mobilSelectItem('FinancialReport')"
            >
              <i
                class="fas fa-file-medical-alt mr-1"
                style="color: #5593f7"
              ></i>
              Financial Report
            </div>
          </router-link>

          <div class="mobilMenuHr"></div>

          <router-link to="/scheduled-drafts">
            <div
              class="mobilNavbarItem"
              @click="mobilSelectItem('ScheduledDrafts')"
            >
              <i
                class="mobilNavbarItemIcon fas fa-calendar-week mr-1"
                style="color: #5593f7"
              ></i>
              <span class="mobilNavbarItemText">Scheduled Drafts</span>
            </div>
          </router-link>

          <div class="mobilMenuHr"></div>

          <router-link to="/transactions">
            <div
              class="mobilNavbarItem"
              @click="mobilSelectItem('Transactions')"
            >
              <i class="fas fa-exchange-alt mr-1" style="color: #5593f7"></i>
              Transactions
            </div>
          </router-link>

          <div class="mobilMenuHr"></div>

          <div
            class="mobilNavbarItem"
            style="color: #f1556c; text-align: center"
            @click="handleLogout()"
          >
            Sign Out
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            font-size: 25px;
            cursor: pointer;
            padding-top: 90px;
          "
          class="fas fa-angle-up"
          @click="toggleMobileNavBar"
        ></div>
      </div>
    </div>

    <div class="leftNavigationBar">
      <div class="col-md-12">
        <img
          :src="logoImgUrl"
          class="pt-3"
          style="width: 10rem; margin-left: 30px"
        />
        <hr style="border-top: 1px solid #ebebeb" />
      </div>

      <div class="col-md-12 mt-3">
        <router-link to="/home-page">
          <div
            class="navbarItem"
            @click="selectItem('Home')"
            :class="{ selected: selectedItem == 'Home' }"
          >
            <i class="fas fa-home mr-1"></i> Home
          </div>
        </router-link>

        <router-link to="/customer-secure-portal">
          <div
            class="navbarItem"
            @click="selectItem('Messages')"
            :class="{ selected: selectedItem == 'Messages' }"
          >
            <i class="fas fa-comments mr-1"></i> Messages
          </div>
        </router-link>

        <div>
          <label class="navHeaders">FINANCIALS</label>
        </div>

        <router-link to="/creditors-screen">
          <div
            class="navbarItem"
            @click="selectItem('Creditors')"
            :class="{ selected: selectedItem == 'Creditors' }"
          >
            <i class="fas fa-file-invoice-dollar mr-1"></i> Creditors
          </div>
        </router-link>

        <router-link to="/documents">
          <div
            class="navbarItem"
            @click="selectItem('Documents')"
            :class="{ selected: selectedItem == 'Documents' }"
          >
            <i class="fas fa-file-alt mr-1"></i> Documents
          </div>
        </router-link>

        <router-link to="/scheduled-drafts">
          <div
            class="navbarItem"
            @click="selectItem('ScheduledDrafts')"
            :class="{ selected: selectedItem == 'ScheduledDrafts' }"
          >
            <i class="fas fa-calendar-week mr-1"></i> Scheduled Drafts
          </div>
        </router-link>

        <router-link to="/transactions">
          <div
            class="navbarItem"
            @click="selectItem('Transactions')"
            :class="{ selected: selectedItem == 'Transactions' }"
          >
            <i class="fas fa-exchange-alt mr-1"></i> Transactions
          </div>
        </router-link>

        <div>
          <label class="navHeaders">REPORTS</label>
        </div>

        <router-link to="/financial-report">
          <div
            class="navbarItem"
            @click="selectItem('FinancialReport')"
            :class="{ selected: selectedItem == 'FinancialReport' }"
          >
            <i class="fas fa-file-medical-alt mr-1"></i> Financial Report
          </div>
        </router-link>
      </div>

      <div
      v-if="!isPortalReadonly"
        @click="toggleSidebar"
        class="navbarItem"
        style="
          position: absolute;
          bottom: 10px;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          cursor: pointer;
          width: 100%;
        "
      >
        <div style="display: flex; align-items: baseline">
          <div
            style="
              background-color: rgb(233, 233, 233);
              border-radius: 50px;
              color: black;
              width: 35px;
              height: 35px;
            "
          >
            <i
              class="fas fa-user-alt"
              style="padding-left: 10px; padding-top: 9px"
            ></i>
          </div>

          <label class="ml-2" style="cursor: pointer">{{
            cropNames(firstName, 18)
          }}</label>
        </div>

        <div class="mr-1">
          <i
            :class="!isSidebarOpen ? 'fas fa-angle-up ' : ' fas fa-angle-down'"
          ></i>
        </div>
      </div>

      <div
        v-if="isSidebarOpen"
        class="userSideBar animate__animated animate__fadeInUp"
      >
        <div
          @click="handleLogout()"
          class="col-md-12 navbarItem"
          style="padding: 10px"
        >
          <i class="fe-log-out mr-1"></i> Sign Out
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deepCopy from "./helpers/deepCopy";
import types from "./views/types";
import { mapState } from "vuex";
let inactivityTimerVar = null;
export default {
  name: "Navigation",
  computed: mapState({
    isPortalReadonly:(state) => state.portalStore.portalReadonly,
  }),
  data() {
    return {
      logoImgUrl: "",
      selectedItem: "Home",
      firstName: "",
      isSidebarOpen: false,
      isMobileNavBar: false,
      firstValue: false,
    };
  },
  methods: {
    checkInactivity() {
      window.onload = this.resetInactivityTimer;
      window.onmousemove = this.resetInactivityTimer;
      window.onmousedown = this.resetInactivityTimer;
      window.ontouchstart = this.resetInactivityTimer;
      window.ontouchmove = this.resetInactivityTimer;
      window.onclick = this.resetInactivityTimer;
      window.onkeydown = this.resetInactivityTimer;
      window.addEventListener("scroll", this.resetInactivityTimer, true);
      this.resetInactivityTimer();
    },
    resetInactivityTimer() {
      if (inactivityTimerVar != null) {
        clearTimeout(inactivityTimerVar);
      }
      inactivityTimerVar = setTimeout(this.timedOutLogout, 900000); //15 minutes
    },
    async timedOutLogout() {
      if (inactivityTimerVar != null) {
        clearTimeout(inactivityTimerVar);
      }
      this.handleLogout();
       
      
    },
    mobileNavBarAnimation() {
      let animation = "";

      if (this.isMobileNavBar) {
        animation =
          "mobileNavigationMenu  animate__animated animate__fadeInDown";
      } else {
        animation =
          "mobileNavigationMenu  animate__animated animate__fadeOutUp";
      }
      return animation;
    },

    selectItem(item) {
      this.selectedItem = item;
    },
    mobilSelectItem(item) {
      this.selectedItem = item;
      this.isMobileNavBar = false;
    },
    cropNames(name, count) {
      count = count < 3 ? 3 : count;
      name = name.length > count ? name.substr(0, count - 3) + "..." : name;
      return name;
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleMobileNavBar() {
      this.firstValue = true;
      this.isMobileNavBar = !this.isMobileNavBar;
    },

    handleLogout() {
      sessionStorage.clear();
      this.$router.replace({ name: "login" });
    },
    async getUserData() {
      let err, result;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (userInfo) {
        this.firstName = userInfo.Name;
        // this.firstName = userInfo.Name.split(" ")[0];
      }

      this.logoImgUrl = sessionStorage.getItem("logoUrl");

      if (!this.logoImgUrl || this.logoImgUrl.length == 0) {
        [err, result] = await this.$store.dispatch(types.GET_COMPANY_LOGO);
        if (result) this.logoImgUrl = result.Data;
      }
    },
  },
  async mounted() {
    await this.getUserData();
    this.$Emitter.on("refreshUserInfo", async () => {
      this.selectedItem = "Messages";
      await this.getUserData();
    });
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if(typeof userInfo != "undefined" && userInfo && userInfo.Id > 0)
    {
      this.checkInactivity();
    }
    
  },
};
</script>

<style>
@media only screen and (max-width: 1000px) {
  .mobileNavigation {
    display: block;
  }

  .leftNavigationBar {
    display: none;
  }

  .mobileNavigationMenu {
    position: fixed;
    top: 0;
    background-color: #f1f1f1;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 100;
  }

  .mobilNavbarItem {
    border: none;
    color: #414141d3;
    font-size: 18px;
    font-weight: 500;
    width: 205px;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
  }
  .mobilNavbarItemText {
    text-align: right;
  }
  .mobilNavbarItemIcon {
    width: 30px;
    text-align: center;
  }
  .mobileNavigation {
    position: fixed;
    top: 0px;
    height: 55px;
    z-index: 9999;
    width: 100%;
    cursor: pointer;
    background-color: #f9fafb;
  }

  .mobilMenuHr {
    border: 1px rgba(199, 199, 199, 0.219) solid;
    width: 85%;
    margin: 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .mobileBar {
    display: none;
  }
  .mobileNavigation {
    display: none;
  }

  .leftNavigationBar {
    display: block;
  }

  .leftNavigationBar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #f9fafb;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .navbarItem {
    border: none;
    color: #414141d3;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
  }
  .navbarItem:hover {
    background-color: #f0f0f0;
  }

  .selected {
    color: #5593f7;
  }

  .navHeaders {
    padding: 10px;
    color: rgb(136, 136, 136);
    font-weight: 500;
  }
  .userSideBar {
    position: fixed;
    bottom: 69px;
    left: 6px;
    height: 4rem;
    width: 238px;
    background-color: #f9fafb;
    transition: right 0.5s;
    border-top: 1px solid #ebebeb;
  }
  .noPadding {
    padding: 0 !important;
  }
}
</style>
