import axios from "axios";

const API_URL = process.env.VUE_APP_PORTAL_API;

const instance = axios.create({
  baseURL: "",
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.userInfo).Token;

    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      window.location.href("/login");
    }
    return Promise.reject(error);
  }
);

export default {
  getAllMessages: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "getall",
      Data: data,
    });
  },
  getAttachmentTypes: () => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "getattachmenttypes",
    });
  },

  sendMessage: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "sendmessage",
      Data: data,
    });
  },
  confirmAuthorization: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "documentsignlog",
      Data: data,
    });
  },
  getMessageById: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "getmessage",
      Data: data,
    });
  },
  getPortalStatements: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "statement",
      Data: data,
    });
  },
  mfaVerificationVerify: (data) => {
    return instance.post(`${API_URL}/api/MfaPortalVerification`, {
      Type: "verify",
      Data: data,
    });
  },
  mfaVerificationSend: (data) => {
    return instance.post(`${API_URL}/api/MfaPortalVerification`, {
      Type: "send",
      Data: data,
    });
  },
  getDashboard: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "dashboard",
      Data: data,
    });
  },
  getDocuments: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "documents",
      Data: data,
    });
  },
  getScheduledDrafts: () => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "futuredrafts",
    });
  },
  getTransactions: () => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "pasttransactions",
    });
  },
  getFinancialReport: () => {
    return instance.post(`${API_URL}/api/PortalMessage`, {
      Type: "cashflow",
    });
  },
};
