<template>
  <div>
    <div class="main" v-if="isLoading">
      <div class="ring"></div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
      <div class="col-md-12 contentArea" style="position: absolute">
        <div>
          <label
            >Statement Date:
            {{ new Date().toLocaleDateString() }}
          </label>
          <hr />
          <label v-if="statementData && statementData.CurrentDedicatedAcctBal"
            >Current Dedicated Account Balance:
            {{ statementData.CurrentDedicatedAcctBal }}
          </label>
        </div>
        <div
          v-if="statementData && statementData.SettledDebts"
          class="pt-4 pb-4 pcTable"
        >
          <DataTable
            :value="statementData.SettledDebts"
            stripedRows
            v-model:filters="filters"
            filterDisplay="row"
            scrollable
            size="small"
            selectionMode="single"
            showGridlines
            paginator
            :rows="15"
          >
            <Column
              field="CreditorName"
              header="Creditor Name"
              style="width: 15rem"
            >
              <template #body="{ data }">
                {{ data.CreditorName }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="OriginalBalance"
              header="Original Balance"
              style="width: 15rem"
            >
              <template #body="{ data }">
                {{ data.OriginalBalance }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="BalanceAtSettlement"
              header="Balance At Settlement"
              style="width: 15rem"
            >
              <template #body="{ data }">
                {{ data.BalanceAtSettlement }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="DateSettled"
              header="Date Settled"
              style="width: 15rem"
            >
              <template #body="{ data }">
                {{ data.DateSettled }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="SettlementAmount"
              header="Settlement Amount"
              style="width: 15rem"
            >
              <template #body="{ data }">
                {{ data.SettlementAmount }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="SettlementFee"
              header="Settlement Fee"
              style="width: 15rem"
            >
              <template #body="{ data }">
                {{ data.SettlementFee }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              field="SettlementStatus"
              header="Settlement Status"
              style="width: 15rem"
            >
              <template #body="{ data }">
                <div
                  style="
                    background-color: #61b4fa;
                    border-radius: 5px;
                    padding: 4px;
                    font-weight: 600;
                    max-width: fit-content;
                  "
                  :style="
                    data.SettlementStatus == 'Settled'
                      ? 'background-color: #8fefb9'
                      : data.SettlementStatus == 'Pending'
                      ? 'background-color: #d4dbf7'
                      : data.SettlementStatus == 'Attorney Review'
                      ? 'background-color: #ffeab4'
                      : data.SettlementStatus == 'Settlement Review'
                      ? 'background-color: #f1bfbf'
                      : data.SettlementStatus == 'Assigned to Negotiator'
                      ? 'background-color: #bbeffd'
                      : ''
                  "
                >
                  {{ data.SettlementStatus }}
                </div>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>

            <Column
              v-if="showPhaseColumn"
              field="SettlementPhase"
              header="Settlement Phase"
              style="width: 15rem"
            >
              <template #body="{ data }">
                {{ data.SettlementPhase }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>
          </DataTable>
        </div>

        <div
          v-if="statementData && statementData.SettledDebts"
          class="mobileTable"
        >
          <div
            v-for="item in statementData.SettledDebts"
            :key="statementData.SettledDebts.indexOf(item)"
            class="mobilScreenTable"
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="infoBoxText">
                <a style="font-weight: 500; font-size: 10px">Creditor Name</a>
                <a style="font-weight: 500; font-size: 15px">{{
                  item.CreditorName
                }}</a>
              </div>

              <div
                style="
                  background-color: #61b4fa;
                  border-radius: 5px;
                  padding: 4px;
                  font-weight: 600;
                "
                class="mr-2"
                :style="
                  item.SettlementStatus == 'Settled'
                    ? 'background-color: #8fefb9'
                    : item.SettlementStatus == 'Pending'
                    ? 'background-color: #d4dbf7'
                    : item.SettlementStatus == 'Attorney Review'
                    ? 'background-color: #ffeab4'
                    : item.SettlementStatus == 'Settlement Review'
                    ? 'background-color: #f1bfbf'
                    : item.SettlementStatus == 'Assigned to Negotiator'
                    ? 'background-color: #bbeffd'
                    : ''
                "
              >
                {{ item.SettlementStatus }}
              </div>
            </div>
            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Original Balance</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.OriginalBalance }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Balance At Settlement</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.BalanceAtSettlement }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />
            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Date Settled</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.DateSettled }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Settlement Amount</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.SettlementAmount }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Settlement Fee</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.SettlementFee }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Settlement Phase</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.SettlementPhase }}</a>
              </div>
            </div>
          </div>
        </div>
        <section
          v-if="statementData && statementData.SettledDebts == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>

        <div class="col-md-12 mt-2" style="padding: 0 0 0 0">
          <label
            v-if="statementData && statementData.Disclaimer"
            style="font-size: 10px"
            >{{ statementData.Disclaimer }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import deepCopy from "../helpers/deepCopy";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "CreditorsScreen",
  components: { DataTable, Column },
  props: {},
  data() {
    return {
      statementData: null,
      isLoading: false,

      filters: {
        CreditorName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        OriginalBalance: { value: null, matchMode: FilterMatchMode.CONTAINS },
        BalanceAtSettlement: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        DateSettled: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SettlementAmount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SettlementFee: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SettlementStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SettlementPhase: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  methods: {
    formatMoney(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });

      return value != null || value != "" || !isNaN(value)
        ? formatter.format(value)
        : "";
    },
  },
  async mounted() {
    this.isLoading = true;
    let err, result;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      let statementErr, statementResult;
      [statementErr, statementResult] = await this.$store.dispatch(
        types.CHECK_LIVE_STATEMENT,
        {
          CustomerId: userInfo.Id,
        }
      );
      if (statementResult && statementResult.Data) {
        this.statementData = deepCopy(statementResult.Data);
      }
    }

    this.isLoading = false;
  },
  computed: {
    showPhaseColumn() {
      return this.statementData.SettledDebts.some(
        (item) =>
          item.SettlementStatus != null || item.SettlementStatus != "Settled"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ring {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0d88e6;
  }
}
.ring:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.datagrid-table {
  border: 1px solid #e4e4e4;
  border-collapse: collapse;
  border-radius: 8px;
  thead {
    background-color: #ffffff;
    color: #2a404c;

    th {
      padding: 15px;
      border-top-color: #ffffff;
      border-bottom: 1px solid #f3eded;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }
  tbody {
    tr {
      font-size: 13px;
      color: #2a404c;
      &:nth-child(even) {
        background-color: #fafafa;
      }
    }
    td {
      padding: 11px 15px;
    }
    tr:hover,
    tr:active {
      background-color: #3bb0da3a;
    }
  }
  .infoBoxText {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 15px;
    font-family: "Open Sans", sans-serif;
  }
}
// Mobile Screen Table Css
.mobilScreenTable {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.mobileScreenHrStyle {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mobileScreenChild {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
}

.mobileScreenText {
  font-weight: 500;
  font-size: 13px;
}

@media only screen and (min-width: 768px) {
  .mobileTable {
    display: none;
  }
  .contentArea {
    padding-top: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .pcTable {
    display: none;
  }
  .contentArea {
    padding-top: 55px;
    padding-left: 0px !important;
  }
}
</style>
