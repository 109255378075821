<template>
  <div class="p-4">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <i
        style="font-size: 60px; color: #3bafda"
        class="ri-lock-password-fill"
      ></i>
      <label style="font-size: 15px">Multi-Factor Authentication (MFA)</label>

      <div style="display: flex; flex-direction: column; align-items: center">
        <label class="text-muted mt-3">
          Step 2: Enter the 6-Digit Verification Code.
        </label>
        <div class="mt-2" style="width: 100%; text-align: center">
          <input
            type="text"
            placeholder="_  _  _  _  _  _"
            v-myMask="{ mask: ['9  9  9  9  9  9'] }"
            style="
              width: 185px;
              display: inline-block;
              border: none !important;
              font-size: 30px;
            "
            v-model="inputs"
            id="txtCode"
          />
        </div>
        <button
          class="btn btn-primary mt-4"
          style="width: 348px"
          @click="submitAuth"
          :disabled="isLoading"
        >
          Submit
        </button>
        <div style="display: flex; justify-content: center">
          <p class="resendCodeText mt-3" @click="resendCode()">Resend Code</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";

export default {
  data() {
    return {
      selectedRadio: "",
      sendVerificationCode: true,
      isLoading: false,
      inputs: "",
    };
  },
  computed: {},
  async mounted() {
    let inputElement = document.getElementById("txtCode");
    if (typeof inputElement != "undefined" && inputElement) {
      inputElement.focus();
    }
    window.addEventListener("keydown", this.handleEnterKey);
  },
  props: {},
  methods: {
    handleEnterKey(event) {
      if (event.key == "Enter") {
        this.submitAuth();
      }
    },
    async resendCode() {
      this.$emit("authCode", false);
      this.inputs = "";
    },

    async submitAuth() {
      this.isLoading = true;
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let mfaToken = null;
      if (this.userInfo != null) {
        mfaToken = await this.getTokenFromLocalStorage(this.userInfo.Email);
      }
      let payload = {
        Code: this.inputs,
        Token: mfaToken,
      };
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.MFA_VERIFICATION_VERIFY,
        payload
      );
      this.isLoading = false;

      if (result) {
        window.removeEventListener("keydown", this.handleEnterKey);
        this.$router.replace({ name: "HomePage" });
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    handleInput(index, event) {
      let value = event.target.value;

      if (isNaN(value)) {
        this.inputs[index] = null;
        return;
      }

      if (value !== "") {
        this.inputs[index] = value;
        const nextIndex = index + 1;

        if (nextIndex < this.inputs.length) {
          this.$refs["input" + nextIndex].focus();
        }
      }
    },
    handleKeyUp(index, event) {
      if (
        event.key.toLowerCase() === "backspace" ||
        event.key.toLowerCase() === "delete"
      ) {
        this.inputs[index] = null;

        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          this.$refs["input" + prevIndex].focus();
        } else {
          event.preventDefault();
        }
      }
    },
    getApiErrorMessage(err) {
      let errMsg = "An Error Occured!";
      if (typeof err != "undefined" && err) {
        if (typeof err.response != "undefined" && err.response) {
          if (err.response.status == 401) {
            errMsg = "You are not authorized to perform this action.";
          }
          if (typeof err.response.data != "undefined" && err.response.data) {
            if (
              typeof err.response.data.Errors != "undefined" &&
              err.response.data.Errors
            )
              errMsg = err.response.data.Errors.join(",");
          }
        }
      }
      return errMsg;
    },
    async getTokenFromLocalStorage(email) {
      let rtn = null;
      let storageName = "mfaInfoPortal-" + (await this.sha256(email));
      let tmpLocal = JSON.parse(localStorage.getItem(storageName));
      if (tmpLocal != null) {
        if (tmpLocal.Token != null) {
          rtn = tmpLocal.Token;
        }
      }
      return rtn;
    },
    async sha256(message) {
      let msgBuffer = new TextEncoder("utf-8").encode(message);
      let hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      let hashArray = Array.from(new Uint8Array(hashBuffer));
      let hashHex = hashArray
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join("");
      return hashHex;
    },
  },
};
</script>

<style>
.splitInputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.splitInput {
  width: 40px;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  text-align: center;
  font-size: 36px;
  cursor: not-allowed;
  pointer-events: none;
}

.splitInput:focus {
  border-bottom: 3px solid #3bafda;
  outline: none;
}

.splitInput:nth-child(1) {
  cursor: pointer;
  pointer-events: all;
}
.resendCodeText {
  font-size: 12px;
  color: #61b1f4;
  cursor: pointer;
}
.resendCodeText:hover {
  color: #a3cff3;
}
</style>
