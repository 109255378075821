import types from "./types";
import api from "@/services/api";
import loginApi from "@/services/loginApi";
import downloadApi from "@/services/downloadApi";
import to from "@/helpers/to";
export default {
  state: {
    messageList: [],
    attachmentTypes: [],
    portalReadonly: false,
  },

  actions: {
    CHANGE_PORTAL_READONLY: async ({ commit }, payload) => {

      commit(types.SET_PORTAL_READONLY,payload);
    },
    PORTAL_LOGIN: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(loginApi.portalLogin(payload));

      return [err, result];
    },
    PORTAL_AUTO_LOGIN: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(loginApi.portalAutoLogin(payload));

      return [err, result];
    },
    GET_COMPANY_LOGO: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(loginApi.getCompanyLogo(payload));

      return [err, result];
    },
    DOWNLOAD_FILE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(downloadApi.downloadFile(payload));

      return [err, result];
    },

    GET_MESSAGE_BY_ID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getMessageById(payload));

      return [err, result];
    },
    GET_ALL_MESSAGES: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getAllMessages(payload));
      if (result) {
        commit(types.SET_ALL_MESSAGES, result.Data);
      }
    },
    GET_ATTACHMENT_TYPES: async ({ commit, state }) => {
      let err, result;
      if (state.attachmentTypes.length > 0) return;

      [err, result] = await to(api.getAttachmentTypes());
      if (result) {
        commit(types.SET_ATTACHMENT_TYPES, result.Data);
      }
    },
    SEND_MESSAGE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.sendMessage(payload));
      return [err, result];
    },

    CONFIRM_AUTHORIZATION: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.confirmAuthorization(payload));
      return [err, result];
    },

    CHECK_LIVE_STATEMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getPortalStatements(payload));
      return [err, result];
    },

    MFA_VERIFICATION_VERIFY: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.mfaVerificationVerify(payload));
      return [err, result];
    },
    MFA_VERIFICATION_SEND: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.mfaVerificationSend(payload));
      return [err, result];
    },

    GET_DASHBOARD: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDashboard(payload));
      return [err, result];
    },
    GET_DOCUMENTS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDocuments(payload));
      return [err, result];
    },

    GET_SCHEDULED_DRAFTS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getScheduledDrafts(payload));
      return [err, result];
    },

    GET_TRANSACTIONS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getTransactions(payload));
      return [err, result];
    },

    GET_FINANCIAL_REPORT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getFinancialReport(payload));
      return [err, result];
    },
  },

  mutations: {
    SET_ALL_MESSAGES: (state, payload) => {
      state.messageList = payload;
    },
    SET_ATTACHMENT_TYPES: (state, payload) => {
      state.attachmentTypes = payload;
    },
    SET_PORTAL_READONLY: (state, payload) => {
      state.portalReadonly = payload;
    },
  },
};
