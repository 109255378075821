import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@/assets/style/bootstrap.scss";
import "@/assets/style/icons.min.css";
import "@/assets/style/icons.scss";
import vueSweetalert2 from "vue-sweetalert2";
import inputmask from "inputmask";
import "sweetalert2/dist/sweetalert2.min.css";
import "animate.css";
import mitt from "mitt";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-blue/theme.css";
import InputText from "primevue/inputtext";
const app = createApp(App);
app.use(store);
app.use(PrimeVue);
app.use(vueSweetalert2);
app.use(router);

app.component("InputText", InputText);

app.mount("#app");
app.config.globalProperties.$Emitter = mitt();
app.directive("myMask", {
  mounted(el, binding) {
    let maskOpts = binding.value;
    maskOpts.showMaskOnHover = false;
    maskOpts.autoUnmask = true;
    inputmask(maskOpts).mask(el);
  },
});
