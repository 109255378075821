import axios from "axios";

const API_URL = process.env.VUE_APP_PORTAL_API;

const instance = axios.create({
  baseURL: "",
  timeout: 120000,
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.userInfo).Token;

    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      window.location.href("/login");
    }
    return Promise.reject(error);
  }
);

export default {
  downloadFile: (data) => {
    return instance.post(`${API_URL}/api/PortalMessage/`, {
      Type: "downloadfile",
      Data: data,
    });
  },
};
