<template>
  <div class="mt-4">
    <div class="cardsSendNew col-xl-12 col-lg-12 col-md-12 col-12">
      <a class="col-xl-12 col-lg-12 col-md-12 col-12">
        <div class="sendNewMessageBar">
          <i
            class="fas fa-chevron-left backIcon"
            @click="handleGoBack()"
            id="backbtn"
          ></i>
          <label
            class="generalLabel mr-3 mt-1"
            style="color: #5593f7; font-size: 20px"
            >{{ messageTitle }}</label
          >
        </div>
      </a>
      <div class="col-md-12">
        <div class="col-md-12 row">
          <label>From:</label>
          <p class="pl-2">{{ userInfo.Name }}</p>
        </div>
        <div class="col-md-12 row">
          <label>To:</label>
          <p class="pl-2">Customer Care</p>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Your Message: *</label>
            <textarea
              class="form-control"
              id="textArea"
              rows="10"
              :class="v$.message.Message.$error ? 'has-error' : ''"
              @blur="v$.message.Message.$touch"
              v-model="message.Message"
            ></textarea>
          </div>
          <div  class="">
            <button
              type="button"
              id="btnAddAttachment"
              class="btn btn-primary mt-3"
              @click="showAttachmentTypes = true"
              :disabled="isPortalReadonly"
            >
              Add an attachment
            </button>
            <div>
              <select
                id="copySettings"
                class="form-control mt-3"
                v-if="showAttachmentTypes"
                v-model="selectedAttachmentTypeId"
              >
                <option value="0">Select an attachment type</option>
                <option
                  v-for="item in attachmentsList"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>

            <div
              class="col-md-12 mt-2"
              style="padding: 0px"
              v-if="selectedAttachmentTypeId && selectedAttachmentTypeId > 0"
            >
              <input
                type="file"
                ref="uploader"
                style="display: none"
                @click="handleFileClick"
                @change="handleFileChange"
              />
              <button
                type="button"
                id="uploadNowButton"
                class="btn btn-primary"
                v-on:click.prevent.stop="$refs.uploader.click()"
              >
                Upload Now
              </button>
            </div>
            <div class="col-md-12 row mt-1" style="padding: 0px">
              <button
                v-for="(item, index) in message.PortalMessageAttachments"
                :key="index"
                type="button"
                class="btn btn-success waves-effect waves-light mr-2"
              >
                <i class="far fa-file" style="color: white"></i>
                {{ item.FileName
                }}<span
                  @click="deleteUploadedFile(index)"
                  class="btn-label-right"
                  ><i class="fas fa-times"></i
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div  class="row col-md-12 justify-content-end mt-3">
          <button
            class="col-md-2 btn btn-success form-control"
            id="btnSend"
            :disabled="!message.Message || loading || isPortalReadonly"
            @click="sendMessage"
          >
            <i
              v-if="loading"
              class="spinner-border"
              style="color: white; width: 20px; height: 20px"
              role="status"
            >
            </i>
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import types from "./types";
import { mapState } from "vuex";

export default {
  name: "SendNewMassage",
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
    replyMessageUserId: {
      type: Number,
      default: () => 0,
    },
    IsReply: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {},
  computed: mapState({
    attachmentsList: (state) => state.portalStore.attachmentTypes,
    isPortalReadonly:(state) => state.portalStore.portalReadonly,
  }),
  async mounted() {
    await this.$store.dispatch(types.GET_ATTACHMENT_TYPES);
    if (this.IsReply) this.messageTitle = "Reply message";
  },
  data() {
    return {
      v$: useValidate(),
      showAttachmentTypes: false,
      selectedAttachmentTypeId: 0,
      loading: false,
      messageTitle: "Send a new message",
      message: {
        Message: "",
        ReplyMessageUserId: 0,
        IsReply: false,
        PortalMessageAttachments: [],
      },
    };
  },
  methods: {
    async sendMessage() {


      if(this.isPortalReadonly == true) return;

      this.loading = true;
      this.message.ReplyMessageUserId = this.replyMessageUserId;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.SEND_MESSAGE,
        this.message
      );

      if (result && result.Data) {
        this.loading = false;
        this.$emit("sendRedirect");
      } else {
        let errMsg = "An Error Occured!";
        if (err) {
          if (err.response.data.Errors)
            errMsg = err.response.data.Errors.join(",");
        }

        this.$swal("Error!", errMsg, "error");
      }

      this.loading = false;
    },
    handleGoBack() {
      this.$emit("goBack");
    },
    handleFileClick() {
      this.$refs.uploader.value = "";
      let input = this.$refs.uploader;
      input.type = "file";
    },
    deleteUploadedFile(indx) {
      this.message.PortalMessageAttachments.splice(indx, 1);
    },
    async handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        let file = e.target.files[0];
        let fileExtension = "";
        if (file) {
          fileExtension = file.name.split(".").pop();
          if (fileExtension == "exe") {
            this.$swal("Error!", "Wrong File Type", "error");
            return;
          }
          if (!this.isValidFileSize(file.size, 50)) {
            this.$swal(
              "Warning!",
              "Maximum allowed file size is 50 MB. Please resize your file.",
              "warning"
            );
            return;
          }

          let url = URL.createObjectURL(file);

          let toBase64 = (file) =>
            new Promise((resolve, reject) => {
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          let data = await toBase64(file);

          if (data.indexOf("data:") > -1) {
            data = data.split(",")[1];
          }

          let uploadData = {
            FileTypeId: this.selectedAttachmentTypeId,
            FileName: file.name,
            DocumentData: data,
          };
          this.message.PortalMessageAttachments.push(uploadData);
        }
      }
    },
    isValidFileSize(size, limitMb) {
      let rtn = true;
      if (size > 1024 * 1024 * limitMb) {
        rtn = false;
      }

      return rtn;
    },
    cropFileNames(name) {
      name = name.length > 14 ? name.substr(0, 30) : name;
      return name;
    },
  },
  validations() {
    return {
      message: { Message: { required } },
    };
  },
};
</script>

<style>
.backgrounds {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardsSendNew {
  position: relative;
  background: #fff;
  border-radius: 5px;
}

.sendNewMessageBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border: 1px #5593f71c solid;
  border-radius: 5px;
  background-color: #b1cfff25;
}
.backIcon {
  font-size: 40px;
  cursor: pointer;
  z-index: 2;
  color: #5593f7;
  padding-left: 10px;
}
.contentsAreaSendMessage {
  padding-top: 140px;
}
.has-error {
  border: 2px solid red !important;
}
</style>
