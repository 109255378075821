<template>
  <div class="main">
    <div v-if="!unAuthorized">
      <div class="ring"></div>
      <label class="redirectingText ml-1 mr-1">Redirecting to portal.</label>
    </div>
    <div v-else>
      <label class="errorText">Your Access Has Expired.</label>
      <div style="display: block; margin-top: 20px">
        <button @click="goToLogin()" class="btn btn-info">
          Please click to login
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import types from "./types";

export default {
  name: "AutoLogin",
  data() {
    return {
      unAuthorized: false,
    };
  },
  async mounted() {
    let authKey = this.$route.query.auth;
    
    let rp = this.$route.query.rp;

    if(typeof rp !="undefined" && rp && rp=="1")
    {
      this.$store.dispatch(types.CHANGE_PORTAL_READONLY,true);
    }

    if (authKey && typeof authKey != "undefined" && authKey.length > 10) {
      let errLogin, resultLogin;
      let data4AutoLogin = {
        EncryptAutoLoginMessage: authKey,
      };
      [errLogin, resultLogin] = await this.$store.dispatch(
        types.PORTAL_AUTO_LOGIN,
        data4AutoLogin
      );


      if (resultLogin) {
        this.unAuthorized = false;
        sessionStorage.setItem("userInfo", JSON.stringify(resultLogin.Data));
        await this.sleep(1500);
        this.$router.replace({ name: "Home" });
        this.$Emitter.emit("refreshUserInfo");
      } else {
        this.unAuthorized = true;
      }
    } else {
      this.unAuthorized = true;
    }
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    goToLogin() {
      this.$router.replace({ name: "login" });
    },
  },
};
</script>
<style scoped>
.main {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}
.ring {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0d88e6;
  }
}
.ring:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}
.redirectingText {
  color: #25a0d1;
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 200px;
  animation: text 3s ease-in-out infinite;
}
.ringError {
  position: absolute;
  width: 200;
  height: 200px;
  border-radius: 50%;
  border: 2px solid red;
}
.errorText {
  color: red;
  font-size: 15px;
  letter-spacing: 2px;
}
</style>
