<template>
  <div v-show="activePage.home">
    <div class="main" v-if="isLoading">
      <div class="ring"></div>
    </div>
    <div>
      <div v-if="!isLoading" class="messageSection">
        <div class="col-md-12 mt-4">
          <button
            type="button"
            class="btn btn-success col-md-12"
            @click="sendMessage"
          >
            Send a New Secure Message
          </button>
        </div>
      </div>

      <div v-if="!isLoading" class="messageSection col-md-12">
        <div
          style="cursor: pointer"
          :style="
            messageId == item.Id
              ? item.IsAuthorizationType && !item.IsDocumentSigned
                ? '-webkit-box-shadow: 0 0 8px 2px #cc1b08'
                : ' -webkit-box-shadow: 0 0 8px 2px #2da524;'
              : ''
          "
          class="messageBox col-md-12 mt-2"
          v-for="item in messageList"
          :key="item.Id"
          @click="handleMessageDetail(item.Id)"
        >
          <div class="col-md-12 messageBoxLayout">
            <div class="messageIconSize">
              <i
                class="messageIconSize"
                :class="item.IsToCustomer ? '' : 'mdi mdi-share'"
                style="color: #54a0ff"
              ></i>

              <label style="font-size: 17px; display: block">{{
                item.IsToCustomer ? "from Customer Care" : "You"
              }}</label>
            </div>
            <div class="messageIconSize">
              <i
                v-if="item.IsAuthorizationType && !item.IsDocumentSigned"
                class="mdi mdi-lead-pencil"
                style="color: #cc1b08; font-size: 22px; margin-top: 3px"
              ></i>
              <i
                v-if="item.PortalMessageAttachments.length > 0"
                class="fas fa-paperclip mt-2"
                style="color: #7f8c8d; font-size: 20px"
              ></i>
              <i
                v-if="item.IsToCustomer"
                class="mdi mdi-share pl-2"
                style="color: #1abc9c; transform: rotate(148deg)"
              ></i>
            </div>
          </div>

          <div class="col-md-6">
            <label style="color: rgba(158, 158, 158, 0.7)">{{
              new Date(item.SendDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "2-digit",
              })
            }}</label>
          </div>
          <div class="col-md-12 messageBoxLayout">
            <div style="max-height: 100px; max-width: 100vh; overflow: hidden">
              <span
                v-if="!item.IsAuthorizationType"
                v-html="item.Message"
              ></span>
              <span
                v-if="item.IsAuthorizationType && !item.IsDocumentSigned"
                style="font-weight: bold"
                >Urgent: Please review this document.</span
              >
              <span
                v-if="item.IsAuthorizationType && item.IsDocumentSigned"
                style="font-weight: bold"
                >Document completed.</span
              >
            </div>
            <div>
              <label class="showDetailsText">Show Details</label>
            </div>
          </div>
        </div>

        <section
          v-if="messageList == 0"
          class="section mt-5"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </div>
      <div v-if="messageList.length >= 3 && !isLoading" class="col-md-12">
        <button
          type="button"
          class="btn btn-primary col-md-12"
          @click="loadMoreMessage"
          :disabled="isLoading"
        >
          Load More Messages
        </button>
      </div>
    </div>
  </div>
  <AttachmentScreen
    v-if="activePage.detail && selectedMessage"
    @goBack="routerFunction('home')"
    @sendRedirect="goHomeNUpdate()"
    @goReplyMessage="sendReply"
    :selectedMessage="selectedMessage"
  ></AttachmentScreen>
  <SendNewMassage
    v-if="activePage.sendMsg && userInfo"
    :userInfo="userInfo"
    :replyMessageUserId="replyMessageUserId"
    :IsReply="isReply"
    @goBack="routerFunction('home')"
    @sendRedirect="goHomeNUpdate()"
  ></SendNewMassage>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import AttachmentScreen from "@/views/AttachmentScreen";
import SendNewMassage from "@/views/SendNewMessage";
import deepCopy from "../helpers/deepCopy";
export default {
  name: "CustomerSecurePortal",
  components: {
    AttachmentScreen,
    SendNewMassage,
  },
  data() {
    return {
      isLoading: false,
      logoImgUrl: "",
      firstName: "",
      userInfo: null,
      rowCount: 3,
      isReply: false,
      selectedMessage: null,
      replyMessageUserId: 0,
      activePage: {
        home: true,
        detail: false,
        sendMsg: false,
      },
      messageId: 0,
      statementData: null,
    };
  },
  computed: mapState({
    messageList: (state) => state.portalStore.messageList,
    //isPortalReadonly:(state) => state.portalStore.portalReadonly,
  }),
  async mounted() {
    let err, result;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.userInfo = userInfo;
    if (userInfo) {
      this.firstName = userInfo.Name.split(" ")[0];
      this.messageId = userInfo.MessageId;
    }
    this.logoImgUrl = sessionStorage.getItem("logoUrl");

    if (!this.logoImgUrl || this.logoImgUrl.length == 0) {
      [err, result] = await this.$store.dispatch(types.GET_COMPANY_LOGO);
      if (result) this.logoImgUrl = result.Data;
    }
    await this.$store.dispatch(types.GET_ATTACHMENT_TYPES);
    await this.getAllMessages();
  },

  methods: {
    async loadMoreMessage() {
      this.rowCount += 3;
      this.getAllMessages();
    },
    sendMessage() {
      this.replyMessageUserId = 0;
      this.isReply = false;
      this.routerFunction("sendMsg");
    },
    sendReply(userId) {
      if (parseInt(userId) > 0) {
        this.isReply = true;
        this.replyMessageUserId = parseInt(userId);
      } else {
        this.isReply = false;
        this.replyMessageUserId = 0;
      }

      this.routerFunction("sendMsg");
    },

    async getAllMessages() {
      let data4AllMessages = {
        RowCount: this.rowCount,
      };
      this.isLoading = true;
      await this.$store.dispatch(types.GET_ALL_MESSAGES, data4AllMessages);
      await this.sleep(1500);
      this.isLoading = false;
    },
    handleMessageDetail(messageId) {
      this.selectedMessage = null;

      let indx = this.messageList.findIndex((x) => x.Id == messageId);
      this.selectedMessage = deepCopy(this.messageList[indx]);
      this.routerFunction("detail");
    },
    async goHomeNUpdate() {
      this.routerFunction("home");
      this.isLoading = true;
      await this.sleep(2000);
      await this.getAllMessages();
      this.isLoading = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    routerFunction(page) {
      if (page == "home") {
        this.activePage.home = true;
        this.activePage.detail = false;
        this.activePage.sendMsg = false;
      } else if (page == "detail") {
        this.activePage.home = false;
        this.activePage.detail = true;
        this.activePage.sendMsg = false;
      } else if (page == "sendMsg") {
        this.activePage.home = false;
        this.activePage.detail = false;
        this.activePage.sendMsg = true;
      }
    },
  },
};
</script>

<style>
.showDetailsText {
  color: #54a0ff;
  cursor: pointer;
}
.line {
  border: 1px solid rgba(78, 78, 78, 0.082);
  width: 1000px;
}

.messageIconSize {
  font-size: 24px;
  cursor: pointer;
  display: flex;
}

.messageBoxLayout {
  display: flex;
  justify-content: space-between;
}

.messageSection {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messageBox {
  height: 150px;
  max-height: 200px;
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: 0 0px 10px rgba(158, 158, 158, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.075);
}
</style>
