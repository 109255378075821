<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
    <div
      class="row"
      style="
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="loginScreenBox col-xl-3 col-lg-4 col-md-8 col-10">
        <div class="companyLogo">
          <img :src="logoImgUrl" />
          <label for="" class="generalLabel mt-2" style="color: #62b4fa"
            >Secure Messaging Portal</label
          >
        </div>
        <div v-if="authenticate && !isAuthenticationCode">
          <MultiFactorAuth
            :authenticate="authenticate"
            @authCode="authenticationCode"
          ></MultiFactorAuth>
        </div>
        <div v-if="isAuthenticationCode">
          <MultiFactorAuthCode
            @authCode="authenticationCode"
          ></MultiFactorAuthCode>
        </div>
        <div v-if="!authenticate" class="formArea mt-2">
          <div class="col-md-11">
            <label class="generalLabel" style="color: #62b1f4">Email *</label>
            <input
              type="text"
              id="email"
              class="login-form-control"
              v-model="email"
              :class="v$.email.$error ? 'has-errors' : ''"
              @blur="v$.email.$touch"
            />
          </div>
          <div class="col-md-11 mt-3">
            <label class="generalLabel" style="color: #62b1f4"
              >Password *</label
            >
            <input
              type="password"
              v-model="ssn"
              class="login-form-control"
              :class="v$.ssn.$error ? 'has-errors' : ''"
              @blur="v$.ssn.$touch"
              @keyup.enter="handleLogin"
            />
          </div>

          <div class="col-md-11 mt-3">
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light"
              style="width: 100%"
              :disabled="v$.$invalid"
              @click="handleLogin()"
            >
              Login
              <i
                v-if="loading"
                class="spinner-border"
                style="color: white; width: 20px; height: 20px"
                role="status"
              >
              </i>
            </button>
          </div>
          <div
            v-if="errText.length > 0"
            class="col-md-11 mt-2"
            style="text-align: center"
          >
            <label
              for="errorAlert"
              class="generalLabel"
              style="color: #ea2027"
              >{{ errText }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { email, required } from "vuelidate/lib/validators";
import types from "./types";
import MultiFactorAuth from "@/views/MultiFactorAuth";
import MultiFactorAuthCode from "@/views/MultiFactorAuthCode";

export default {
  name: "Login",
  components: { MultiFactorAuth, MultiFactorAuthCode },
  emits: ["authCode"],
  data() {
    return {
      isAuthenticationCode: false,
      authenticate: false,
      v$: useValidate(),
      email: "",
      ssn: "",
      loading: false,
      logoImgUrl: "",
      errText: "",
      mfa: null,
    };
  },
  async mounted() {
    let err, result;
    [err, result] = await this.$store.dispatch(types.GET_COMPANY_LOGO);
    if (result) {
      this.logoImgUrl = result.Data;
      sessionStorage.setItem("logoUrl", result.Data);
    }
  },
  methods: {
    authenticationCode(value) {
      this.isAuthenticationCode = value;
    },
    async getTokenFromLocalStorage(email) {
      let rtn = null;
      let storageName = "mfaInfoPortal-" + (await this.sha256(email));
      let tmpLocal = JSON.parse(localStorage.getItem(storageName));
      if (tmpLocal != null) {
        if (tmpLocal.Token != null) {
          rtn = tmpLocal.Token;
        }
      }
      return rtn;
    },
    async resetTokenStorage(email, token) {
      let storageName = "mfaInfoPortal-" + (await this.sha256(email));
      localStorage.removeItem(storageName);
      let content = { Token: token };
      localStorage.setItem(storageName, JSON.stringify(content));
    },

    async sha256(message) {
      let msgBuffer = new TextEncoder("utf-8").encode(message);
      let hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      let hashArray = Array.from(new Uint8Array(hashBuffer));
      let hashHex = hashArray
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join("");
      return hashHex;
    },

    getApiErrorMessage(err) {
      let errMsg = "An Error Occured!";
      if (typeof err != "undefined" && err) {
        if (typeof err.response != "undefined" && err.response) {
          if (err.response.status == 401) {
            errMsg = "You are not authorized to perform this action.";
          }
          if (typeof err.response.data != "undefined" && err.response.data) {
            if (
              typeof err.response.data.Errors != "undefined" &&
              err.response.data.Errors
            )
              errMsg = err.response.data.Errors.join(",");
          }
        }
      }
      return errMsg;
    },

    async handleLogin() {
      this.loading = true;
      sessionStorage.clear();
      this.v$.$validate();
      if (!this.v$.$error) {
        let mfaToken = await this.getTokenFromLocalStorage(this.email);
        let dataToLogin = {
          Ssn: this.ssn,
          email: this.email,
          MfaToken: mfaToken,
        };
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.PORTAL_LOGIN,
          dataToLogin
        );

        if (result) {
          this.loading = false;
          sessionStorage.setItem("userInfo", JSON.stringify(result.Data));
          sessionStorage.setItem("logoUrl", this.logoImgUrl);

          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

          if (
            userInfo != null &&
            userInfo.Mfa != null &&
            userInfo.Mfa.IsMfaRequired == true
          ) {
            this.authenticate = true;
          } else {
            await this.resetTokenStorage(userInfo.Email, userInfo.Mfa.MfaToken);
            this.$router.replace({ name: "HomePage" });
          }
        } else {
          let errMsg = "An Error Occured!";
          if (err) {
            if (err.response.data.Errors)
              errMsg = err.response.data.Errors.join(",");
          }
          this.errText = errMsg;
        }
      }
      this.loading = false;
    },
  },
  validations() {
    return {
      email: { required, email },
      ssn: { required },
    };
  },
};
</script>

<style scoped>
.loginScreenBox {
  height: auto;
  padding: 20px 0;
  background: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.formArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.companyLogo {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px 0;
  border-bottom: 1px solid silver;
}

.has-errors {
  border-bottom: 1px solid #e60808 !important;
}

.login-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.9rem + 2px);
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0px;
  border-bottom: 1px solid #62b1f4;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
