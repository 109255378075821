<template>
  <div class="mt-4">
    <div class="main" v-if="!message">
      <div class="ring"></div>
    </div>
    <div class="cards col-xl-12 col-lg-12 col-md-12 col-12" v-if="message">
      <a class="col-xl-12 col-lg-12 col-md-12 col-12" style="z-index: 1000">
        <div class="sendNewMessageBar">
          <div>
            <i
              @click="handleGoBack()"
              class="fas fa-chevron-left backIcon"
              id="comeBack"
            ></i>
          </div>
          <div>
            <h5 class="pr-3" style="color: #5593f7">
              {{ message.IsToCustomer ? "from Customer Care" : "You" }}
            </h5>
            <h5 class="pr-3" style="color: #5593f7">
              {{
                new Date(message.SendDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "numeric",
                  minute: "2-digit",
                })
              }}
            </h5>
          </div>
        </div>
      </a>
      <div class="col-md-12 contentArea" style="position: absolute;">
        <div  class="col-md-12 row" @contextmenu.prevent="() => {}">
          <span style="width: 100%" v-html="message.Message"></span>
        </div>
        <div class="col-md-12">
          <label v-if="message.PortalMessageAttachments.length > 0"
            >Attachments:</label
          >
        </div>

        <div
          class="col-md-12 mt-2"
          style="background-color: #f4f4f4"
          v-if="message.PortalMessageAttachments.length > 0"
        >
          <div
            class="form-group downloadBox"
            v-for="item in message.PortalMessageAttachments"
            :key="item.Id"
          >
            <label>{{ cropNames(item.FileName, 43) }}</label>
            <i
              class="ri-download-2-fill downloadIcon"
              @click="handleDownload(item.FileName)"
            ></i>
          </div>
        </div>
        <div class="pt-5">
          <button
            v-if="!message.IsAuthorizationType  && !isPortalReadonly"
            class="col-md-12 btn btn-primary"
            id="btnReply"
            @click="replyCustomerCare(message)"
            style="width: 100%"
          >
            Reply to Customer Care
          </button>
          <button
            v-if="message.IsAuthorizationType && !message.IsDocumentSigned"
            class="col-md-12 btn btn-success"
            id="btnReply"
            @click="confirmAuthorization(message)"
            style="width: 100%"
            :disabled="isPortalReadonly"
          >
            I Agree
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import deepCopy from "../helpers/deepCopy";
export default {
  name: "AttachmentScreen",
  props: {
    selectedMessage: {
      type: Object,
      default: () => {},
    },
  },

  components: {},
  computed: mapState({
    isPortalReadonly:(state) => state.portalStore.portalReadonly,
  }),
  async mounted() {
    let err, result;
    [err, result] = await this.$store.dispatch(types.GET_MESSAGE_BY_ID, {
      MessageId: this.selectedMessage.Id,
    });
    if (result) {
      this.message = deepCopy(result.Data);
    } else {
      let errMsg = "An Error Occured!";
      if (err) {
        if (err.response.data.Errors)
          errMsg = err.response.data.Errors.join(",");
      }

      this.$swal("Error!", errMsg, "error");
    }
  },
  data() {
    return {
      message: null,
    };
  },
  methods: {
    async confirmAuthorization() {

      if(this.isPortalReadonly == true) return;

      let err, result;

      [err, result] = await this.$store.dispatch(types.CONFIRM_AUTHORIZATION, {
        MessageId: this.selectedMessage.Id,
      });

      if (result) {
        this.$emit("sendRedirect");
        this.$swal("Success", "Signed.", "success");
      } else {
        let errMsg = "An Error Occured!";
        if (err) {
          if (err.response.data.Errors)
            errMsg = err.response.data.Errors.join(",");
        }

        this.$swal("Error!", errMsg, "error");
      }
    },
    replyCustomerCare(msg) {
      this.$emit("goReplyMessage", msg.UserId);
    },
    handleGoBack() {
      this.$emit("goBack");
    },
    async handleDownload(fileName) {
      let dataToDownload = { FileName: fileName };
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.DOWNLOAD_FILE,
        dataToDownload
      );

      if (result) {
        this.saveByteArray(fileName, result);
      }
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/*" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    cropNames(name, count) {
      count = count < 3 ? 3 : count;
      name = name.length > count ? name.substr(0, count - 3) + "..." : name;
      return name;
    },
  },
};
</script>

<style>
.backgrounds {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards {
  position: relative;
  background: #fff;
  border-radius: 5px;
}

.sendNewMessageBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.backIcon {
  font-size: 40px;
  cursor: pointer;
}
.downloadBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.downloadIcon {
  font-size: 25px;
  color: #3bafda;
  cursor: pointer;
}
.main {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.ring {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0d88e6;
  }
}
.ring:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}
</style>
