import axios from "axios";

const API_URL = process.env.VUE_APP_PORTAL_API;

export default {
  portalLogin: async (data) => {
    return axios.post(
      `${API_URL}/api/PortalMessageLogin`,
      {
        Type: "login",
        Data: data,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  },
  portalAutoLogin: async (data) => {
    return axios.post(
      `${API_URL}/api/PortalMessageLogin`,
      {
        Type: "autologin",
        Data: data,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  },
  getCompanyLogo: async () => {
    return axios.post(
      `${API_URL}/api/PortalMessageLogin`,
      {
        Type: "logo",
        Data: {
          Mode: "",
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  },
};
