<template>
  <div class="">
    <div class="main" v-if="isLoading">
      <div class="ring"></div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
      <div class="col-md-12 contentArea" style="position: absolute">
        <div
          v-if="financialReportData"
          class="pb-4 pcTable"
          style="overflow-y: auto"
        >
          <DataTable
            :value="financialReportData"
            stripedRows
            v-model:filters="filters"
            filterDisplay="row"
            scrollable
            showGridlines
            size="small"
            selectionMode="single"
            paginator
            :rows="10"
          >
            <Column field="Date" header="Date">
              <template #body="{ data }">
                {{ formatShortDate(data.Date) }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>
            <Column field="TransactionTypeDesc" header="Transaction Type">
              <template #body="{ data }">
                {{ data.TransactionTypeDesc }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>
            <Column field="Amount" header="Amount">
              <template #body="{ data }">
                {{ formatMoney(data.Amount) }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>
            <Column field="Balance" header="Balance">
              <template #body="{ data }">
                {{ formatMoney(data.Balance) }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>
            <Column field="Memo" header="Memo">
              <template #body="{ data }">
                {{ data.Memo }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  @input="filterCallback()"
                  class="p-column-filter"
                />
              </template>
            </Column>
          </DataTable>
        </div>

        <div v-if="financialReportData" class="mobileTable">
          <div
            v-for="item in financialReportData"
            :key="financialReportData.indexOf(item)"
            class="mobilScreenTable"
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="infoBoxText">
                <a style="font-weight: 500; font-size: 10px">Date</a>
                <a style="font-weight: 500; font-size: 15px">{{
                  formatShortDate(item.Date)
                }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Transaction Type</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.TransactionTypeDesc }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Amount</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ formatMoney(item.Amount) }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Balance</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ formatMoney(item.Balance) }}</a>
              </div>
            </div>

            <hr class="mobileScreenHrStyle" />

            <div class="mobileScreenChild">
              <div>
                <a class="mobileScreenText">Memo</a>
              </div>
              <div>
                <a class="mobileScreenText">{{ item.Memo }}</a>
              </div>
            </div>
          </div>
        </div>

        <section
          v-if="financialReportData && financialReportData == 0"
          class="section"
          style="text-align: center"
        >
          <div class="content has-text-grey has-text-centered mb-4">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line"
              ></i>
            </p>
            <p class="noMargin">Nothing here.</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import deepCopy from "../helpers/deepCopy";
import { FilterMatchMode } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  name: "CreditorsScreen",
  components: { DataTable, Column },
  props: {},
  data() {
    return {
      financialReportData: null,
      isLoading: false,
      filters: {
        Date: { value: null, matchMode: FilterMatchMode.CONTAINS },
        TransactionTypeDesc: {
          value: null,
          matchMode: FilterMatchMode.STARTS_WITH,
        },
        Amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Balance: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Memo: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },

  async mounted() {
    this.isLoading = true;
    let err, result;
    [err, result] = await this.$store.dispatch(types.GET_FINANCIAL_REPORT);
    if (
      result &&
      result.Data &&
      result.Data.Data &&
      result.Data.Data.CashFlows
    ) {
      this.financialReportData = deepCopy(result.Data.Data.CashFlows).map(
        (item) => ({
          ...item,
          Date: this.formatShortDate(item.Date),
        })
      );
    }
    this.isLoading = false;
  },
  computed: {},
  methods: {
    formatShortDate(date) {
      return date == null || date == ""
        ? ""
        : new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
    },
    formatMoney(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });

      return value != null || value != "" || !isNaN(value)
        ? formatter.format(value)
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.ring {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #4fa8eb;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0d88e6;
  }
}
.ring:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.datagrid-table {
  border: 1px solid #e4e4e4;
  border-collapse: collapse;
  border-radius: 8px;
  thead {
    background-color: #ffffff;
    color: #2a404c;

    th {
      padding: 15px;
      border-top-color: #ffffff;
      border-bottom: 1px solid #f3eded;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }
  tbody {
    tr {
      font-size: 13px;
      color: #2a404c;
      &:nth-child(even) {
        background-color: #fafafa;
      }
    }
    td {
      padding: 11px 15px;
    }
    tr:hover,
    tr:active {
      background-color: #3bb0da3a;
    }
  }
  .infoBoxText {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 15px;
    font-family: "Open Sans", sans-serif;
  }
}
// Mobile Screen Table Css
.mobilScreenTable {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.mobileScreenHrStyle {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mobileScreenChild {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
}

.mobileScreenText {
  font-weight: 500;
  font-size: 13px;
}

@media only screen and (min-width: 768px) {
  .mobileTable {
    display: none;
  }
  .contentArea {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 768px) {
  .pcTable {
    display: none;
  }
  .contentArea {
    padding-top: 55px;
    padding-left: 0px !important;
  }
}
</style>
