export default {
  CHANGE_PORTAL_READONLY:"CHANGE_PORTAL_READONLY",
  SET_PORTAL_READONLY:"SET_PORTAL_READONLY",
  GET_MESSAGE_BY_ID: "GET_MESSAGE_BY_ID",
  GET_ALL_MESSAGES: "GET_ALL_MESSAGES",
  SET_ALL_MESSAGES: "SET_ALL_MESSAGES",

  GET_ATTACHMENT_TYPES: "GET_ATTACHMENT_TYPES",
  SET_ATTACHMENT_TYPES: "SET_ATTACHMENT_TYPES",

  PORTAL_LOGIN: "PORTAL_LOGIN",
  PORTAL_AUTO_LOGIN: "PORTAL_AUTO_LOGIN",
  GET_COMPANY_LOGO: "GET_COMPANY_LOGO",

  DOWNLOAD_FILE: "DOWNLOAD_FILE",
  SEND_MESSAGE: "SEND_MESSAGE",
  CONFIRM_AUTHORIZATION: "CONFIRM_AUTHORIZATION",

  CHECK_LIVE_STATEMENT: "CHECK_LIVE_STATEMENT",

  MFA_VERIFICATION_VERIFY: "MFA_VERIFICATION_VERIFY",
  MFA_VERIFICATION_SEND: "MFA_VERIFICATION_SEND",

  GET_DASHBOARD: "GET_DASHBOARD",

  GET_DOCUMENTS: "GET_DOCUMENTS",

  GET_SCHEDULED_DRAFTS: "GET_SCHEDULED_DRAFTS",
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_FINANCIAL_REPORT: "GET_FINANCIAL_REPORT",
};
