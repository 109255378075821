<template>
  <div class="p-4">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <i
        style="font-size: 60px; color: #3bafda"
        class="ri-lock-password-fill"
      ></i>
      <label style="font-size: 15px">Multi-Factor Authentication (MFA)</label>

      <div>
        <label class="mt-3">
          Your account security is our priority. We've activated Multi-Factor
          Authentication for added protection.
        </label>
        <label class="text-muted mt-3">
          Step 1: First, begin by selecting where you'd like to receive the
          verification code.
        </label>

        <div class="form-check form-switch text-center mt-3">
          <div
            v-if="mfaRadioControl && mfaRadioControl.includes('sms')"
            class="form-check-inline"
          >
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="sms"
              v-model="selectedRadio"
            />
            <label class="form-check-label" for="inlineRadio1">SMS</label>
          </div>
          <div
            v-if="mfaRadioControl && mfaRadioControl.includes('email')"
            class="form-check form-check-inline"
          >
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="email"
              v-model="selectedRadio"
            />
            <label class="form-check-label" for="inlineRadio2">Email</label>
          </div>
          <div
            v-if="mfaRadioControl && mfaRadioControl.includes('call')"
            class="form-check form-check-inline"
          >
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio3"
              value="call"
              v-model="selectedRadio"
            />
            <label class="form-check-label" for="inlineRadio3">Call</label>
          </div>
        </div>

        <div
          class="pt-3"
          style="display: flex; justify-content: center; text-align: center"
        >
          <label v-if="selectedRadio == 'email'" class="form-check-label">
            The code will be sent to: {{ maskedEmail }}</label
          >
          <label
            v-if="selectedRadio == 'sms' || selectedRadio == 'call'"
            class="form-check-label"
            style="text-align: center"
          >
            The code will be sent to: {{ maskedPhone }}</label
          >
        </div>

        <button
          class="btn btn-primary col-md-12 mt-3"
          @click="sendCode"
          :disabled="isLoading"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
export default {
  data() {
    return {
      selectedRadio: "",
      isLoading: false,
      mfaRadioControl: null,
      maskedEmail: "",
      maskedPhone: "",
    };
  },
  async mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      this.userInfo != null &&
      this.userInfo.Mfa != null &&
      this.userInfo.Mfa.Options != null
    ) {
      this.mfaRadioControl = this.userInfo.Mfa.Options;
    }

    if (this.userInfo != null && this.userInfo.Mfa != null) {
      if (this.userInfo.Mfa.Masked) {
        if (this.userInfo.Mfa.Masked.Email) {
          this.maskedEmail = this.userInfo.Mfa.Masked.Email;
        }
        if (this.userInfo.Mfa.Masked.Phone) {
          this.maskedPhone = this.userInfo.Mfa.Masked.Phone;
        }
      }
    }
  },
  props: {
    authenticate: {
      Type: Boolean,
      Defult: false,
    },
  },
  methods: {
    async sendCode() {
      if (this.selectedRadio == "") {
        this.$swal("Warning!", "Please make a choice.", "warning");
        return;
      }
      this.isLoading = true;
      let payload = {
        Option: this.selectedRadio,
      };
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.MFA_VERIFICATION_SEND,
        payload
      );
      this.isLoading = false;
      if (result) {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo != null) {
          await this.resetTokenStorage(userInfo.Email, result.Data.Token);
        }
        this.$emit("authCode", true);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    getApiErrorMessage(err) {
      let errMsg = "An Error Occured!";
      if (typeof err != "undefined" && err) {
        if (typeof err.response != "undefined" && err.response) {
          if (err.response.status == 401) {
            errMsg = "You are not authorized to perform this action.";
          }
          if (typeof err.response.data != "undefined" && err.response.data) {
            if (
              typeof err.response.data.Errors != "undefined" &&
              err.response.data.Errors
            )
              errMsg = err.response.data.Errors.join(",");
          }
        }
      }
      return errMsg;
    },

    async resetTokenStorage(email, token) {
      let storageName = "mfaInfoPortal-" + (await this.sha256(email));
      localStorage.removeItem(storageName);
      let content = { Token: token };
      localStorage.setItem(storageName, JSON.stringify(content));
    },
    async sha256(message) {
      let msgBuffer = new TextEncoder("utf-8").encode(message);
      let hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      let hashArray = Array.from(new Uint8Array(hashBuffer));
      let hashHex = hashArray
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join("");
      return hashHex;
    },
  },
};
</script>

<style></style>
