import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login";
import Home from "../views/CustomerSecurePortal";
import AutoLogin from "../views/AutoLogin";
import HomePage from "../views/HomePage";
import CreditorsScreen from "../views/CreditorsScreen";
import Documents from "../views/Documents";
import ScheduledDrafts from "../views/ScheduledDrafts";
import Transactions from "../views/Transactions";
import FinancialReport from "../views/FinancialReport";

const routes = [
  {
    path: "/login",
    component: Login,
    beforeEnter(to, from, next) {
      next();
    },
    name: "login",
    meta: {
      title: "Login",
      hideNavbar: true,
    },
  },
  {
    path: "/AutoLogin",
    component: AutoLogin,
    beforeEnter(to, from, next) {
      next();
    },
    name: "AutoLogin",
    meta: {
      title: "AutoLogin",
    },
  },
  {
    path: "/",

    component: Home,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    name: "Home",
    meta: {
      title: "Home",
    },
  },
  {
    name: "HomePage",
    path: "/home-page",
    component: HomePage,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    meta: {
      title: "HomePage",
    },
  },
  {
    path: "/customer-secure-portal",
    component: Home,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    meta: {
      title: "CustomerSecurePortal",
    },
  },
  {
    path: "/creditors-screen",
    component: CreditorsScreen,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    meta: {
      title: "CreditorsScreen",
    },
  },

  {
    path: "/documents",
    component: Documents,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    meta: {
      title: "Documents",
    },
  },
  {
    path: "/scheduled-drafts",
    component: ScheduledDrafts,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    meta: {
      title: "ScheduledDrafts",
    },
  },
  {
    path: "/transactions",
    component: Transactions,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    meta: {
      title: "Transactions",
    },
  },
  {
    path: "/financial-report",
    component: FinancialReport,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    meta: {
      title: "FinancialReport",
    },
  },

  { path: "/:catchAll(.*)", component: Login },
  /*{
    path: "/send-new-message",

    component: SendNewMessage,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    name: "SendNewMessage",

  },
  {
    path: "/attachment-screen",
    component: AttachmentScreen,
    beforeEnter(to, from, next) {
      isAuthenticated() ? next() : next("/login");
    },
    name: "AttachmentScreen",

  },*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isAuthenticated() {
  let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  if (!userInfo) return false;

  if (!userInfo.Token || userInfo.Token.length == 0) return false;

  return true;
}

export default router;
