<template>
  <div id="app">
    <div v-if="!$route.meta.hideNavbar">
      <Navigation />
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Navigation from "@/Navigation.vue";

export default {
  components: {
    Navigation,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
#app {
  display: flex;
  min-height: auto;
}

@media only screen and (min-width: 1000px) {
  #app > div:first-child {
    width: 250px;
  }

  #app > div:last-child {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  #app > div:last-child {
    background-color: white;
    flex-grow: 1;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
