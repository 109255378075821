<template>
  <div>
    <div class="main" v-if="isLoading">
      <div class="ring"></div>
    </div>
    <div
      v-if="dashboardData && !isLoading"
      style="position: absolute; margin-top: 95px"
    >
      <div class="col-md-12">
        <div>
          <p class="welcomeText">{{ greeting }}, {{ userName }}</p>
        </div>

        <div v-if="dashboardData.Warning">
          <div class="col-xl-12 warningBox">
            <i class="fas fa-exclamation-triangle exclamation"></i>
            <label class="ml-1" style="color: #ffbf00">{{
              dashboardData.Warning
            }}</label>
          </div>
        </div>
      </div>

      <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
        style="margin-top: 50px; flex-wrap: wrap"
      >
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 infoBox mb-2"
          style="display: flex; justify-content: space-between"
        >
          <div class="infoBoxText">
            <a
              v-if="dashboardData.Balance != null"
              style="font-weight: 500; font-size: 25px; color: #2ad375"
              >{{ formatMoney(dashboardData.Balance) }}</a
            >
            <a style="font-weight: 500; font-size: 15px">Available Balance</a>
          </div>
          <div>
            <img
              :src="require('@/assets/images/dashboardIcon1.png')"
              alt=""
              style="height: 100px"
            />
          </div>
        </div>
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 infoBox mb-2"
          style="display: flex; justify-content: space-between"
        >
          <div class="infoBoxText">
            <a
              v-if="dashboardData.DraftAmount != null"
              style="font-weight: 500; font-size: 25px; color: #2ad375"
              >{{ formatMoney(dashboardData.DraftAmount) }}</a
            >
            <a style="font-weight: 500; font-size: 15px">Next Payment Amount</a>
          </div>
          <div>
            <img
              :src="require('@/assets/images/dashboardIcon2.png')"
              style="height: 100px"
            />
          </div>
        </div>
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 infoBox"
          style="display: flex; justify-content: space-between"
        >
          <div class="infoBoxText">
            <a
              v-if="dashboardData.DraftDate != null"
              style="font-weight: 500; font-size: 25px; color: black"
              >{{ formatShortDate(dashboardData.DraftDate) }}</a
            >
            <a style="font-weight: 500; font-size: 15px">Next Payment Date</a>
          </div>
          <div>
            <img
              :src="require('@/assets/images/dashboardIcon3.png')"
              alt=""
              style="height: 100px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import deepCopy from "../helpers/deepCopy";
export default {
  name: "HomePage",
  data() {
    return {
      userName: "",
      dashboardData: [],
      greeting: "",
      isLoading: false,
    };
  },

  async mounted() {
    this.isLoading = true;
    this.setGreeting();
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.userName = userInfo.Name;
      let err, result;
      await this.sleep(2000);
      [err, result] = await this.$store.dispatch(types.GET_DASHBOARD);
      if (result && result.Data) {
        this.dashboardData = deepCopy(result.Data);
      }
    }

    this.isLoading = false;
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    setGreeting() {
      let userTime = new Date().getHours();
      if (userTime < 12) {
        this.greeting = "Good Morning";
      } else if (userTime < 18) {
        this.greeting = "Good Afternoon";
      } else {
        this.greeting = "Good Evening";
      }
    },
    formatMoney(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });

      return value != null || value != "" || !isNaN(value)
        ? formatter.format(value)
        : "";
    },
    formatShortDate(date) {
      return date == null || date == ""
        ? ""
        : new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
    },
  },
};
</script>

<style>
.welcomeText {
  font-family: "Open Sans", sans-serif;
  font-size: 3em;
  font-weight: 300;
}

.exclamation {
  color: #ffbf00;
  margin: 8px 0px 0px 0px;
}
.warningBox {
  border-radius: 5px;
  background-color: #fff8e3;
  border: 1px #ffbf0031 solid;
}

.infoBox {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  background-color: #fff;
}

.infoBoxText {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 15px;
  font-family: "Open Sans", sans-serif;
}

@media only screen and (min-width: 768px) {
  .warningBox {
    border-radius: 5px;
    background-color: #fff8e3;
    border: 1px #ffbf0031 solid;
  }
}
@media only screen and (max-width: 768px) {
  .warningBox {
    border-radius: 5px;
    background-color: #fff8e3;
    border: 1px #ffbf0031 solid;
    font-size: 11px;
  }
  .welcomeText {
    font-family: "Open Sans", sans-serif;
    font-size: 2em;
    font-weight: 300;
  }
}
</style>
